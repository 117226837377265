import React, {useState} from 'react'
import {useDispatch} from "react-redux"
import Layout from "../../../components/NonUkResidents/HCP/layout"
import SEO from "../../../components/seo"
import { graphql,  Link } from 'gatsby';
import styles from "../../../components/Investor/news.module.css"
import {showNonUkHcpAwardsApplicationPopup} from "../../../actions/nonUkResidents/hcp/awards/awards"
import {showNonUkResidentsHcpDisclaimerPopup} from "../../../actions/nonUkResidents/hcp/index"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer" 
import ReactDOMServer from 'react-dom/server';
import ReactHtmlParser from "react-html-parser"
import { deriveHomePathFromSpace, isNullUndefined , addDisclaimersToCmsLinks, richTextOptions, capitalizeFirstLetter} from "../../../util"
import { getDefaultNonUkResidentsHcpDisclaimerPopupConfigs, config } from '../../../util/configs';
import queryString from "query-string"
import { ToastContainer, toast } from 'react-toastify';
import { ValidateRoute } from '../../../util/route-helper';
import TemplateWrapper from "../../../components/TemplateWrapper"


export const pageQueryAwardsTemplateNonUkHcp = graphql`
    query ($slug: String!, $spaceId: String!) {
        contentfulAwardsTemplate(slug: { eq: $slug }, spaceId: {eq: $spaceId}) {
                spaceId
                slug
                title
                bodyText {
                    json
                }
                parentMenu {
                    awards_template {
                      parentMenu {
                        title
                        slug
                      }
                    }
                }
        }
    }
`

const AwardsTemplatePageNonUkHcp = (props) => {
    const dispatch = useDispatch()

    const {slug, title, bodyText} = props.data.contentfulAwardsTemplate;

    let richText = documentToReactComponents(isNullUndefined(bodyText) ? bodyText : bodyText.json, richTextOptions)

    let bodyHtml = ReactDOMServer.renderToStaticMarkup(richText)

    const openApplyNowPopup = (e) => {
        let actionConfig = {
            payload: {
                show: true,
                award: slug,
                description: null,
                okLabel: "Send",
                closeLabel: "Cancel",
                okCallback: () => {
                    toast.success("Your application was submitted successfully");
                },
                closeCallback: () => {}
            }
        }

        dispatch(showNonUkHcpAwardsApplicationPopup(actionConfig));
    }

    const showExternalLinkDisclaimer = (externalUrl) => {
        let okCallback = () => window.open (externalUrl, "_blank")
        let closeCallback = () => {}
        let actionConfig = getDefaultNonUkResidentsHcpDisclaimerPopupConfigs(okCallback, closeCallback)
        dispatch(showNonUkResidentsHcpDisclaimerPopup(actionConfig));
    }
    let [veevaDisclaimer, setVeevaDisclaimer] = React.useState(false);


    React.useEffect(() => {
        let {veevaDisclaimer} = queryString.parse(props.location.search || "");
  
        if(veevaDisclaimer){
          setVeevaDisclaimer(true);
          showExternalLinkDisclaimer(config.defaultBaseUrl)
        }
  
    }, []);
    return (
        <TemplateWrapper>
            <Layout>
        <SEO title={`${capitalizeFirstLetter(title)} | Non-UK Residents | HCP`} />
            <div className={"container"} style={{marginTop: "15px"}} >
        <ToastContainer
                    position="top-right"
                    autoClose={3500}
                    hideProgressBar={false}
                    newestOnTop={true}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
         
                <div className={"row"}>
                    <div className={"col-12"}>
                        <a style={{cursor: "pointer"}} title="go back" onClick={e => {
                            e.preventDefault();
                            window.history.back();
                        }} className={styles.go_back}>❮</a> 
                        <h2 style={{marginTop: '20px', color: '#2b5f51'}}>
                            {title}
                        </h2>
                        <hr/>
                    </div>
                </div>

               

                <div className={"row"}>
                    <div className={"col-12"}>
                      
                        <div className={styles.author}>
                        <h5>
                        </h5>
                        </div>
                      
                    </div>
                </div>

                <div className={"row"}>
                    <div className="col-12">
                        {ReactHtmlParser(bodyHtml, {transform: (node, index) => addDisclaimersToCmsLinks(node, index, showExternalLinkDisclaimer)})}
                    </div>
                </div>

                <button className="custom_btn dark bordered_btn mb-2" onClick={openApplyNowPopup}>Apply Now</button>
            </div>
      </Layout>
        </TemplateWrapper>
    )
}

export default AwardsTemplatePageNonUkHcp